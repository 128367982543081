@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';




body{

  background: radial-gradient(146.17% 58.65% at 50% 50%, #0075B8 0%, #002F77 100%) !important;
  background-repeat: no-repeat;
  height:max-content;
  background-size:contain;

  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;

}

.diff{
 font-family: "Single Day", cursive;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-container {
 margin-left: 10px;
margin-right: 10px;

}

.example3{
	color: black;
	font-size: 55px;
	-webkit-text-stroke: 1px black;
	-webkit-text-fill-color: white;
}

/* table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

td {
  height: 50px;
} */

.disabled {
  background-color: #f2f2f2; /* Gray color */
}

.marr{
  margin: 0px 20px 20px 0px;
}

/* Add additional styling as needed */

.my-bordered-text {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

