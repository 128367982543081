@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body{
  background: radial-gradient(146.17% 58.65% at 50% 50%, #0075B8 0%, #002F77 100%) !important;
  background-repeat: no-repeat;
height: 100%;
  background-size:cover;

  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;

}

.example3{
	color: black;
	font-size: 34px;
	-webkit-text-stroke: 1px black;
	-webkit-text-fill-color: white;
}

.element {
	text-shadow:
		-1px -1px 0 #000,
		1px -1px 0 #000,
		-1px 1px 0 #000,
		1px 1px 0 #000;
}


@layer components {
  .custom-ul {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @screen sm {
    .custom-ul {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @screen lg {
    .custom-ul {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}


.container {
  display: flex;
  justify-content: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.table {

  border-collapse: collapse;
  margin: 10px 0;
}

/* .table th {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  background-color: white;
} */

.tddd{
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  background-color: white;
}

.thhhh {
  background-color: #ffff;
  border: 1px solid black;
  padding: 10px;
  text-align: center;
}

.favour-cell {
  vertical-align: middle;
  border: none;
  background-color: white;
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

/* .table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.table th, .table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.table th {
  background-color: #f2f2f2;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
} */



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 5px 20px;
  padding-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close {
  cursor: pointer;
  margin-left: 250px;
  font-size: 30px;
}

.inputt{
  font-family: Schoolbell;
    margin: 10px;
    padding: 10px;
    max-width: 100px;
    min-width: 80px;
    border: 1px solid black;
    font-size: 1.2em;
}

/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;

}

td, th {

  padding: 8px;
  text-align: center;
}

tr {
  background-color: #fff;
} */
.pxx-autto{
  margin-left: 20%;
  margin-right: 20%;
}


.my-bordered-text {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
